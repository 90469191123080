
import $ from "jquery";
import defaults from "configs.json";
import { className } from "styles.scss";

export default class Sniper {

    /**
     * Get a config by key
     *
     * @param {string} key The name of the config
     * @param {*} default_value An optional default value
     * @returns {*} The config's value or the specified default
     */
    static getConfig(key, default_value = null) {
        let value = window.localStorage.getItem(key);
        if (value === null) {
            value = key in defaults ? defaults[key] : default_value;
        }

        return value;
    }

    /**
     * Return a function that will only be invoked after it stops being called for
     * n milliseconds.
     * 
     * @param {Function} fn The original function to debounce
     * @param {*} timeout The number of milliseconds
     * @returns {Function} The debounced version of the function
     */
    static debounce(fn, timeout = 100) {
        let timer = null;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { fn.apply(this, args); }, timeout);
        };
    }

    /**
     * Create a Sniper instance
     *
     * @param {Element} el The HTML element to append the instance to
     */
    constructor(el) {
        /** @type {string} The current img size */
        this._size = null;

        /** @type {jQuery} The root element */
        this._$el = $("<figure/>")
            .addClass(className)
            .css("padding", this.getConfig("margin"))
            .css("background-color", this.getConfig("background"))
            .appendTo(el);

        // Add the img elements
        const rows = this.getConfig("rows");
        const cols = this.getConfig("cols");
        const total = rows * cols;
        const initial = this.getConfig("initial");

        for (let index = 0; index < total; index++) {
            const frame = initial[index] ?? 0;

            const $sub_figure = $("<div/>")
                .addClass("sub-figure")
                .appendTo(this.$el);

            $("<img/>")
                .data("index", index)
                .data("frame", frame)
                .on("pointerover", this.onImagePointerOver.bind(this))
                .appendTo($sub_figure);

            $("<img/>")
                .addClass("preload")
                .data("index", index)
                .data("frame", frame + 1)
                .appendTo($sub_figure);
        }

        $(window).on("resize", this.constructor.debounce(this.onResize.bind(this)));
        this.onResize();
    }

    /**
     * Get the root element
     *
     * @returns {Element} The root HTML element
     */
    get $el() {
        return this._$el;
    }

    /**
     * Get a config
     *
     * This is non-static shortcut of the static method with the same name
     *
     * @param {string} key The name of the config
     * @param {*} default_value An optional default value
     * @returns {*} The value
     */
    getConfig(key, default_value) {
        return this.constructor.getConfig(key, default_value);
    }

    /**
     * Get an image src corresponding to an index and frame
     *
     * @param {number} index The image index
     * @param {number} frame The frame
     *
     * @returns {string} The corresponding image src uri
     */
    getImageSrc(index, frame = 0) {
        return `images/${this._size}/${frame}-${index}.jpg`;
    }

    /**
     * Update an image's src attribute
     *
     * @param {jQuery} $img The jQuery img element
     */
    updateImageSrc($img) {
        const frame = $img.data("frame");
        const index = $img.data("index");
        const src = this.getImageSrc(index, frame);

        $img.attr("src", src);
    }

    /**
     * Update all images
     */
    updateImages(width, height) {
        $("img", this.$el).each((i, img) => {
            const $img = $(img)
                .css('width', `${width}px`)
                .css('height', `${height}px`);

            this.updateImageSrc($img);
        });
    }

    /**
     * Increment an image's frame
     *
     * @param {jQuery} $img The jQuery img element
     */
    incrementImageFrame($img) {
        let frame = parseInt($img.data("frame"), 10) + 1;

        if (frame >= this.getConfig("frames")) {
            frame = 0;
        }

        $img.data("frame", frame);

        this.updateImageSrc($img);
    }

    /**
     * Image pointerover event callback
     *
     * @param {object} evt The jQuery event
     */
    onImagePointerOver(evt) {
        const $img = $(evt.target);
        this.incrementImageFrame($img);
    }

    /**
     * Window resize event callback
     */
    onResize() {
        const sizes = this.getConfig("sizes");
        const cols = this.getConfig("cols");
        const rows = this.getConfig("rows");

        const available_tile_width = Math.floor(this.$el.width() / cols);
        const available_tile_height = Math.floor(this.$el.height() / rows);

        let matched_size = null;
        Object.entries(sizes).forEach(([size, opt], i, arr) => {
            const { tile_width, tile_height } = opt;

            if (
                matched_size === null ||
                (
                    tile_width >= available_tile_width ||
                    tile_height >= available_tile_height
                )
            ) {
                matched_size = size;
            }
        });

        this._size = matched_size;

        const { tile_width, tile_height } = sizes[this._size];
        let actual_tile_width = tile_width;
        let actual_tile_height = tile_height;

        if (available_tile_width < tile_width || available_tile_height < tile_height) {
            const scale_factor = available_tile_width / available_tile_height > tile_width / tile_height
                ? available_tile_height / tile_height
                : available_tile_width / tile_width;

            actual_tile_width = Math.round(actual_tile_width * scale_factor);
            actual_tile_height = Math.round(actual_tile_height * scale_factor);
        }

        this.updateImages(actual_tile_width, actual_tile_height);
    }

}